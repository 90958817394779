import React, { type FC, useEffect, useState } from 'react';
import { Table, TableBody, TableContainer, TableHead } from '@mui/material';

import { useDebounce } from '../../../../hooks/useDebounce';
import { EmptyTableComponent } from '../../../../components';
import { SavingsTableCell } from './SavingsTableCell/SavingsTableCell';
import { StyledTableRow } from '../StyledTableRow/StyledTableRow';
import { StyledTableCell } from '../StyledTableCell/StyledTableCell';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getSavings } from '../../../../redux/reducers/saving';
import { type ITableContainerProps } from '../../interface/savingsPageInterfaces';
import { SavingsElementHeight } from '../../helper';
import { SavingsTableHeader } from './SavingsTableHeader/SavingsTableHeader';

export const SavingsTable: FC<ITableContainerProps> = ({ setSection, pageContentHeight, screenWidth }) => {
  const dispatch = useAppDispatch();
  const savings = useAppSelector((state) => state.saving);
  const [searchInputValue, setSearchInputValue] = useState<string | null>(null);
  const [debouncedInputValue, setDebouncedInputValue] = useState<string | null>(null);
  const [flagReload, setFlagReload] = useState<boolean>(false);

  useDebounce({ value: searchInputValue, delay: 600, action: setDebouncedInputValue });

  const savingsStorage = 'Savings-Table/search-input'

  const isTable = (screenWidth <= 1239);

  const getDataFromLocalStorage = (): void => {
    const searchInputStorage = localStorage.getItem(savingsStorage);
    setSearchInputValue(searchInputStorage ?? '');
    setFlagReload(true);
  }

  useEffect(() => {
    getDataFromLocalStorage();
  }, []);

  useEffect(() => {
    if (flagReload && (debouncedInputValue !== null)) {
      localStorage.setItem(savingsStorage, debouncedInputValue);
      dispatch(getSavings(debouncedInputValue));
    }
  }, [debouncedInputValue, flagReload])

  return (
    <>
      <SavingsTableHeader searchInputValue={ searchInputValue } setSearchInputValue={ setSearchInputValue }/>
      <TableContainer
        sx={{
          maxHeight: `${pageContentHeight}px`,
          paddingBottom: '0',
          borderRadius: '4px'
        }}
      >
      <Table stickyHeader aria-label="customized table" sx={{ width: '100%', height: '100%', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell sx={{ height: '2px', padding: '0' }} />
          </StyledTableRow>
        </TableHead>
          {
            (savings.length > 0)
              ? (
                <TableBody sx={{ height: `${pageContentHeight - SavingsElementHeight.BorderTable}px` }}>
                  { savings.map((item) => (
                    <StyledTableRow
                      key={ item._id }
                      sx={{
                        boxSizing: 'border-box',
                        height: isTable ? '100%' : '104px !important',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                      <StyledTableCell
                        sx={{ height: isTable ? '100%' : '104px !important', backgroundColor: 'var(--CFD-theme-Surface-1) !important' }}>
                        <SavingsTableCell row={ item } setSection={ setSection } isTable={ isTable }/>
                      </StyledTableCell>
                    </StyledTableRow>
                  )) }
                  </TableBody>
                )
              : (
                  <EmptyTableComponent columnCount={ 10 } height={ `${pageContentHeight - SavingsElementHeight.PaddingTop}px` }/>
                )
          }
        </Table>
      </TableContainer>
    </>
  );
}
