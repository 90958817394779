import React, { type FC, useState, type KeyboardEvent, type ChangeEvent, useRef, useEffect } from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { readChat, getChatMessages, sendMessage, getMessage, type MessagesInterface } from '../../redux/reducers/chat'
import { FilePickerButtonComponent, ProgressComponent, useSnackbar } from '../../components';
import { HeaderLiveChatComponent } from './HeaderLiveChatComponent/HeaderLiveChatComponent';
import { ContentLiveChatComponent } from './ContentLiveChatComponent/ContentLiveChatComponent';
import { FooterLiveChatComponent } from './FooterLiveChatComponent/FooterLiveChatComponent';
import { LiveChatPickedFileModal } from './LiveChatPickedFileModal/LiveChatPickedFileModal';
import { socket } from '../../web/socket';
import { useBodyOverflow } from '../../hooks/useBodyOverflow';

import style from './LiveChatComponent.module.css';
import { useWindowSize } from '../../hooks/useWindowSize';

interface InterfaceLiveChatComponent {
  setOpen: (el: boolean) => void
  open: boolean
}

export const LiveChatComponent: FC<InterfaceLiveChatComponent> = ({ setOpen, open }) => {
  const dispatch = useAppDispatch();
  const { _id: userId } = useAppSelector((state) => state.user);
  const chat = useAppSelector((state) => state.chat);
  const { handleOpen } = useSnackbar();
  const [widthScreen] = useWindowSize();
  const isMobile = widthScreen <= 600;
  useBodyOverflow((open && isMobile));

  const [sendMessageValue, setSendMessageValue] = useState('');
  const [sendMessageFileValue, setSendMessageFileValue] = useState('');
  const [showBody, setShowBody] = useState<boolean>(true);
  const [filePicked, setFilePicked] = useState<File | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const filePickedRef = useRef<HTMLInputElement | null>(null);
  const chatBoxRef = useRef<HTMLDivElement | null>(null);

  const scrollDown = (): void => {
    if (chatBoxRef.current !== null) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const removeImageHandler = (): void => {
    setFileUrl('');
    setFilePicked(null);
    setSendMessageValue('');
    setSendMessageFileValue('');
  }

  const sendMessageHandler = async (): Promise<void> => {
    if (userId !== undefined) {
      setIsBtnLoading(true);
      const formData: FormData = new FormData();

      if (((filePickedRef.current?.files) != null) && filePicked !== null) {
        const file = filePickedRef.current.files[0];
        formData.append('file', file, file.name);
        formData.append('content', sendMessageFileValue.trim());
        formData.append('id', chat?._id?.length > 0 ? chat._id : 'undefined');
        formData.append('sender', userId);
      } else {
        formData.append('content', sendMessageValue.trim());
        formData.append('id', chat?._id?.length > 0 ? chat._id : 'undefined');
        formData.append('sender', userId);
      }
      scrollDown();
      removeImageHandler();
      await dispatch(sendMessage({ data: formData, handleOpen }));
      if (filePickedRef.current !== null) {
        filePickedRef.current.files = null;
        filePickedRef.current.value = '';
      }
      setIsBtnLoading(false);
    }
  }

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>, id: string): void => {
    const { target: { value: inputValue } } = event;
    if (id === 'caption') setSendMessageFileValue(inputValue)
    else setSendMessageValue(inputValue);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter' && !isBtnLoading) {
      event.preventDefault();
      sendMessageHandler();
    }
  }

  const handleMessageByClick = (): void => {
    if (!isBtnLoading) {
      sendMessageHandler();
    }
  }

  const filePickerHandler = (inputElement: HTMLInputElement | null): void => {
    if (inputElement !== null) {
      inputElement.click();
    }
  }

  useEffect(() => {
    setIsBtnLoading(!chat.isUserCanSendAMessage)
  }, [chat.isUserCanSendAMessage]);

  useEffect(() => {
    const getChat = async (): Promise<void> => {
      if (userId !== undefined) dispatch(getChatMessages(userId));
    }

    setLoader(true);
    if (userId !== undefined) {
      getChat();
      const data = {
        id: chat._id,
        content: sendMessageValue,
        sender: userId
      }
      dispatch(readChat(data))
    }
    setLoader(false);
  }, [userId]);

  useEffect(() => {
    scrollDown();
    if (chat._id !== undefined && userId !== undefined) {
      if (socket !== undefined && socket.connected) {
        socket.on(`getChatMessage/${chat._id}/${chat.user}`, (response): void => {
          dispatch(getMessage(response as MessagesInterface))
        });
      }
    }

    return () => {
      socket.off(`getChatMessage/${chat._id}/${chat.user}`);
    }
  }, [chat]);

  const messageValid = (sendMessageValue.length > 0 || filePicked !== null);

  return (
  <div className={ style.liveChatWrapper }>
    <HeaderLiveChatComponent setOpen={ setOpen } showBody={ showBody } setShowBody={ setShowBody } />
      { showBody
        ? <>
        { loader
          ? <ProgressComponent type='circular' />
          : <ContentLiveChatComponent ref={ chatBoxRef }/>
        }
        {fileUrl?.length > 0 && (
          <LiveChatPickedFileModal
            sendMessageFileValue={ sendMessageFileValue }
            inputChangeHandler={ inputChangeHandler }
            handleKeyDown={ handleKeyDown }
            sendMessageHandler={ handleMessageByClick }
            messageValid={ messageValid }
            removeImageHandler={ removeImageHandler }
            filePicked={ filePicked }
            fileUrl={ fileUrl }
            isBtnLoading= { isBtnLoading }
          />
        )}
        <div className={ style.footerLiveChat }>
          <div className={ style.inputWrapper }>
            <FilePickerButtonComponent
              ref={ filePickedRef }
              setFileUrl={ setFileUrl }
              handleFileChange={ setFilePicked }
              handleDocsPicker={ filePickerHandler }
              id='chatFile'
            />
            <FooterLiveChatComponent
              sendMessageValue={ sendMessageValue }
              inputChangeHandler={ inputChangeHandler }
              handleKeyDown={ handleKeyDown }
              sendMessageHandler={ handleMessageByClick }
              messageValid={ messageValid }
              isBtnLoading= { isBtnLoading }
              id='message'
            />
          </div>
        </div>
      </>
        : null }
  </div>
  )
}
