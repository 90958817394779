import React, { useEffect, type FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks/redux';
import { getUserByToken } from '../redux/reducers/user';
import { type RouteLayoutInterface } from '../interfaces';
import { NavigationRail, Header, Footer } from '../components';
import styles from '../App.module.css';

export const PrivateLayout: FC<RouteLayoutInterface> = ({ isUserAuth, children }): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  useEffect(() => {
    if (!isUserAuth) {
      navigate('/login');
      return;
    }

    dispatch(getUserByToken())
  }, [isUserAuth]);

  return (
    <div className={styles.pageContainer}>
      <NavigationRail />
      <Header openMenu={ openMenu } setOpenMenu={ setOpenMenu }/>
      <div className={styles.contentContainer}>
        {children}
      </div>
      <Footer setOpenMenu={ setOpenMenu }/>
    </div>
  );
}
