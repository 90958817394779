import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthWrapper } from './auth/AuthWrapper';
import { SnackbarWrapper } from './components';
import { defineDefaultLangCodeCorrect, getLanguages } from './redux/reducers/language';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { selectLanguage } from './redux/selectors/language';
import './App.css';

function App (): JSX.Element {
  const dispatch = useAppDispatch()

  const activeLanguage = useAppSelector(selectLanguage);

  useEffect(() => {
    Promise.all([
      dispatch(defineDefaultLangCodeCorrect()),
      dispatch(getLanguages())
    ]);
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <SnackbarWrapper>
          <AuthWrapper key={activeLanguage} />
        </SnackbarWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
// test
