import React, { type FC, type Dispatch, type SetStateAction, useState, useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Accordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { t } from 'i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { setShowFooter } from '../../../redux/reducers/showFooter';
import { Market, News, Chart, Table, BuySell } from '../components';
import { ButtonComponent } from '../../../components';
import { BuySellModalMobile } from './BuySellModalMobile/BuySellModalMobile';
import { setTradingMode } from '../../../redux/reducers/tradingMode';
import { TradeElementHeight } from '../helper';
import { useWindowSize } from '../../../hooks/useWindowSize';

import style from './MobileViewTrade.module.css';

const CustomAccordion = styled(Accordion)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  border: '1px solid var(--CFD-theme-State-layers-outline-opacity_16)',
  width: '100%',
  height: '63px',
  boxShadow: 'none',
  '&.Mui-expanded': {
    borderBottom: 'unset',
    marginTop: '0',
    borderRadius: '6px 6px 0 0'
  },
  ' .MuiButtonBase-root': {
    padding: '4px 4px 4px 4px',
    height: '100%',
    color: 'var(--CFD-theme-System-OnSurface)'
  },
  ' .MuiCollapse-root': {
    margin: '-1px'
  }
}));

const CustomAccordionDetails = styled(AccordionDetails)(() => ({
  backgroundColor: 'var(--CFD-theme-System-Surface)',
  padding: '16px',
  overflowY: 'auto',
  minHeight: '80vh',
  borderRadius: '0 0 6px 6px',
  ' .MuiButtonBase-root': {
    height: '100vh'
  },
  ' .newItemWrapper': {
    marginBottom: '16px',

    '&:last-of-type': {
      marginBottom: '0'
    }
  }
}));

interface InterfaceMobileViewTrade {
  uniqueId: number
  isMarketShown: boolean
  isNewsShown: boolean
  isOpened: boolean
  setIsMarketShown: Dispatch<SetStateAction<boolean>>
  setIsNewsShown: Dispatch<SetStateAction<boolean>>
  setMaxTradingHeight: Dispatch<SetStateAction<number>>
  calcHeight: number
}

export const MobileViewTrade: FC<InterfaceMobileViewTrade> = ({
  uniqueId,
  isMarketShown,
  isNewsShown,
  isOpened,
  setIsMarketShown,
  setIsNewsShown,
  setMaxTradingHeight,
  calcHeight
}) => {
  const dispatch = useAppDispatch();
  const [isNewsMobileShow, setIsNewsMobileShow] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const [modalBuySell, setModalBuySell] = useState<boolean>(false);
  const [screenWidth] = useWindowSize();
  const [paddingBetweenNewsMarket, setPaddingBetweenNewsMarket] = useState<'0px' | '234px'>('0px')
  const { tradingMode } = useAppSelector((state) => state.tradingMode);

  const isMobile = screenWidth <= 600;

  const btnTradingModeHandle = (): void => {
    dispatch(setTradingMode(false));
  }

  const btnBuySellHandler = (): void => {
    setModalBuySell(true);
  }

  const handleExpansion = (): void => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (tradingMode) dispatch(setShowFooter(false));
    else dispatch(setShowFooter(true));
  }, [tradingMode])

  useEffect(() => {
    setPaddingBetweenNewsMarket(isNewsMobileShow ? '234px' : '0px');
  }, [isNewsMobileShow]);

  return (
    <>
    { !tradingMode
      ? <div className={ style.tradeMobileWrapper }
          style={{ height: `${calcHeight + TradeElementHeight.NewsAccordionHeader}px` }}
        >
        <News isMarketShown={ isMarketShown } isNewsShown={ isNewsShown } setIsNewsMobileShow={ setIsNewsMobileShow } calcHeight={ calcHeight }/>
        <div
          style={{
            paddingTop: paddingBetweenNewsMarket,
            paddingBottom: isMobile ? `${TradeElementHeight.MobileFooter}px` : `${TradeElementHeight.Footer}px`,
            height: `calc(100% - ${TradeElementHeight.NewsAccordionHeader} - ${TradeElementHeight.MarketMargin} - ${TradeElementHeight.MobileFooter})px`
          }}
        >
          <Market uniqueId={ uniqueId } isMarketShown={ isMarketShown } setIsNewsMobileShow={ setIsNewsMobileShow }/>
        </div>
      </div>
      : <>
        <div className={ style.tradeModeWrapper }>
            <div className={ style.tradeModeWrapperHeader }>
              <h1 className={ style.tradeModeWrapperHeaderTitle }>{t('trading_page.labels.trading')}</h1>
              <button className={ style.crossBtn } onClick={ btnTradingModeHandle }>
                <CloseOutlinedIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)' }}/>
              </button>
            </div>
            <div className={ style.tradeModeMarketWrapper }>
              <CustomAccordion
                expanded={ expanded }
                onChange={ handleExpansion }
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: 'var(--CFD-theme-System-OnSurface)', padding: '0 16px' }}/>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ borderRadius: '0' }}
                >
                  <span className={ style.marketTitle }>{t('trading_page.labels.market')}</span>
                </AccordionSummary>
                <CustomAccordionDetails>
                  <div style={{ height: '80vh' }}>
                    <Market uniqueId={ uniqueId } isMarketShown={ isMarketShown } hideHeader setExpanded={ setExpanded } />
                  </div>
                </CustomAccordionDetails>
              </CustomAccordion>
            </div>
            <div
              className={ style.tradeModeChartWrapper }
            >
              <Chart
                isOpened={ isOpened }
                uniqueId={ uniqueId }
                isNewsShown={ isNewsShown }
                isMarketShown={ isMarketShown }
                setIsMarketShown={ setIsMarketShown }
                setIsNewsShown={ setIsNewsShown }
                calcHeight={ calcHeight }
              />
            </div>
              <div
                className={ style.tradeModeTable }
                style={{ display: expanded ? 'none' : 'flex', bottom: isTableExpanded ? `${TradeElementHeight.MobileOrderAccordionBody}px` : '40px' }}
              >
                <Table
                  uniqueId={ uniqueId }
                  setMaxTradingHeight={ setMaxTradingHeight }
                  isTableExpanded={ isTableExpanded }
                  setIsTableExpanded={ setIsTableExpanded }
                  calcHeight={ calcHeight }
                />
              </div>
              <div
                className={ style.tradeModeBtnContainer }
                style={{ display: expanded ? 'none' : 'flex' }}
              >
                <ButtonComponent btnstyle='buy' onClick={ btnBuySellHandler }>{t('trading_page.labels.buy')}</ButtonComponent>
                <ButtonComponent btnstyle='sell' onClick={ btnBuySellHandler }>{t('trading_page.labels.sell')}</ButtonComponent>
              </div>
          </div>
          <BuySellModalMobile open={ modalBuySell } setOpen={ setModalBuySell }>
            <BuySell isOpened={ isOpened } uniqueId={ uniqueId } setModalBuySell={ setModalBuySell } />
          </BuySellModalMobile>
        </>
      }
    </>
  )
}
