import axios, { type AxiosResponse } from 'axios';
type ApiHelperInterface = (args: { method: string, path: string, data?: object | string, params?: object | URLSearchParams | string }) => Promise<AxiosResponse<any>>;

interface ErrorDataInterface { success: boolean, isTokenExpired: boolean, message: string }
interface ErrorResponseInterface { data: ErrorDataInterface };
interface ErrorInterface { response: ErrorResponseInterface }

export const apiHelper: ApiHelperInterface = async ({ method = '', path = '', data = '', params = '' }): Promise<AxiosResponse> => {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL + path}`,
      data,
      params,
      headers: {
        Authorization: localStorage.getItem('token') ?? ''
      }
    });

    return response;
  } catch (error) {
    const currentError = error as ErrorInterface;
    const uId = localStorage.getItem('uId') ?? null;

    if (!currentError.response.data.success && currentError.response.data.isTokenExpired && uId !== null) {
      localStorage.removeItem('token');
      localStorage.removeItem('uId');
      window.open('/login', '_self');
    }

    throw error;
  }
}
